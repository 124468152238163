<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" style="margin-bottom:0">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="音频标题" :initialContent="detail.name" :disabled="true"></inputItem>
          <inputItem title="转录状态" :initialContent="audioState(detail.state)" :disabled="true"></inputItem>
          <inputItem title="导入时间" :initialContent="formatDate(detail.add_time, 'yyyy-MM-dd hh:mm:ss')" :disabled="true">
          </inputItem>
          <inputItem title="完成时间" :initialContent="formatDate(detail.end_time, 'yyyy-MM-dd hh:mm:ss')" :disabled="true">
          </inputItem>
          <inputItem title="关联项目" :initialContent="detail.project_name" :disabled="true" v-if="detail.project_name">
          </inputItem>
          <inputItem title="关联客户" :initialContent="detail.customer_name" :disabled="true" v-if="detail.customer_name">
          </inputItem>
          <inputItem title="联系人员" :initialContent="detail.linkman_name" :disabled="true" v-if="detail.linkman_name">
          </inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="音频文件" label="音频文件">
          <div style="display: flex;justify-content: space-around;align-content: center;margin-top: 10px;height: 54px;">
            <div>
              <audio id="audioPlayer" controls>
                <source :src="'http://oa.kdainfo.com' + detail.file_path" type="audio/mpeg">
              </audio>
            </div>
            <div style="display: flex;align-content: center;">
              <button @click="playAudio()" style="border: 1px;border-radius: 20px;width: 60px;">后台</button>
            </div>
          </div>

        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="总结内容" label="总结内容">
          <div class="audio-content">{{ item != null && item.summary ? item.summary.replace(/\r/g, '\n') : "暂无总结内容" }}
          </div>
        </cube-scroll-nav-panel>
        <!-- <cube-scroll-nav-panel key="转录内容" label="转录内容">
          <div class="audio-content">{{detail!=null&&detail.content? detail.content:"暂无转录内容"}}</div>
        </cube-scroll-nav-panel> -->
      </cube-scroll-nav>
    </div>
  </div>
</template>

<script>
import { GetAudio } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction } from "@/utils/action.js";
import { audioState } from "@/utils/state.js";

const wx = window.wx;
export default {
  data: function () {
    return {
      id: "",
      detail: null,
      item: null
    };
  },
  methods: {
    formatDate: formatDate,
    audioState: audioState,
    dataRequest: function () {
      loadDetailAction(this, GetAudio, { id: this.id }, (data) => {
        this.detail = data?.list?.[0]?.model ?? null;
        this.item = data?.list?.[0]?.items?.[0] ?? null;
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
    playAudio: function () {
      wx.miniProgram.navigateTo({
        url: '/pages/audio/audio?id=' + this.detail.id
      });
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
<style>
.audio-content {
  white-space: pre-wrap;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  padding: 5px;
}
</style>